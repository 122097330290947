import Api from "@/apis/Api";

export default {
	methods: {
		showAddForwarderModal() {
			this.resetModal();
			this.modalOptions.title = this.$t('heading.hosting.emailForwarders.modal.add.title');
			this.modalOptions.icon = "$plus";
			this.modalOptions.buttons.unshift({
				label: this.$t('button.emailForwarder.add'),
				color: "primary",
				onclick: (modal) => {
					modal.$refs.form.validate() && this.addForwarder(modal.formValues);
				},
			});
			this.modalOptions.formFields = [
				{
					label: this.$t('form.label.email'),
					type: "text-select",
					suffix: "@",
					tooltip: "tooltip.add_forwarder.email",
					textName: "email",
					selectName: "domain",
					rules: [
						(v) => /.+@.+/.test(v) ||
							this.$t('form.validation.required', {field: this.$t('form.label.email')})
					],
					options: [{
						label: this.selectedEmailDomain.domain,
						value: this.selectedEmailDomain.domain
					}]
				},
				{
					label: this.$t('form.label.destination'),
					name: "destination",
					tooltip: "tooltip.add_forwarder.destination",
					type: "text",
					rules: [
						(v) => !!v ||
							this.$t('form.validation.required', {field: this.$t('form.label.destination')}),
						(v) => /.+@.+/.test(v) ||
							this.$t('form.validation.email', {field: this.$t('form.label.destination')}),
					],
				},
			];
			this.modalOptions.item = { domain: this.selectedEmailDomain.domain };
			this.modalOptions.open = true;
		},
		addForwarder(formData) {
			this.modalOptions.persistent = true;
			this.modalOptions.submitting = true;
			this.modalOptions.submittingSuccess = "";
			this.modalOptions.submittingError = "";

			Api.post(
				`/services/${this.service.id}/email/domains/${this.selectedEmailDomain.domain}/forwarders`,
				formData
			)
				.then(() => {
					this.modalOptions.submittingSuccess = this.$t('notification.hosting.emailForwarders.add.success');
					this.highlightItem = {
						email: `${formData.email}@${formData.domain}`,
						forward_to: formData.destination,
					};
					this.reloadEmailForwarders(this.selectedEmailDomain);

					this.$store.dispatch("addAlert", {
						success: true,
						successMessage: this.modalOptions.submittingSuccess,
					});
					this.modalOptions.open = false;
				})
				.catch((error) => {
					this.modalOptions.submittingError = Api.getErrorMessage(error);

					this.$store.dispatch("addAlert", {
						success: false,
						errorMessage: this.modalOptions.submittingError,
					});
				})
				.finally(() => {
					this.modalOptions.persistent = false;
					this.modalOptions.submitting = false;
				});
		},
		showDeleteForwarderModal(item) {
			this.resetModal();
			this.modalOptions.item = item;
			this.modalOptions.title = this.$t('heading.hosting.emailForwarders.modal.delete.title');
			this.modalOptions.color = "error";
			this.modalOptions.icon = "$alertwarning";

			this.modalOptions.formFields = [
				{
					message: `<b>${this.$t('message.confirmAction')}</b>`,
					label: this.$t('form.confirmDelete.emailForwarder'),
					name: "confirm",
					type: "checkbox",
					required: true,
				},
			];
			this.modalOptions.buttons.unshift({
				label: this.$t('button.emailForwarder.delete'),
				color: "error",
				onclick: (modal) => {
					modal.$refs.form.validate() && this.deleteForwarder(item);
				},
			});
			this.modalOptions.open = true;
		},
		deleteForwarder(item) {
			this.modalOptions.persistent = true;
			this.modalOptions.submitting = true;
			this.modalOptions.submittingSuccess = "";
			this.modalOptions.submittingError = "";

			Api.delete(
				`/services/${this.service.id}/email/domains/${this.selectedEmailDomain.domain}/forwarders/${
					item.email
				}?forward_to=${encodeURIComponent(item.forward_to)}`
			)
				.then(() => {
					this.modalOptions.submittingSuccess = this.$t('notification.hosting.emailForwarders.delete.success');
					this.reloadEmailForwarders(this.selectedEmailDomain);

					this.$store.dispatch("addAlert", {
						success: true,
						successMessage: this.modalOptions.submittingSuccess,
					});
					this.modalOptions.open = false;
				})
				.catch((error) => {
					this.modalOptions.submittingError = Api.getErrorMessage(error);

					this.$store.dispatch("addAlert", {
						success: false,
						errorMessage: this.modalOptions.submittingError,
					});
				})
				.finally(() => {
					this.modalOptions.persistent = false;
					this.modalOptions.submitting = false;
				});
		},
	}
}